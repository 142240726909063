<template lang="pug">
  span
    portal(to="candidates-account")
      div(class="fill-width")
        select-menu(
          :options="$store.state.cbrUser.items"
          itemText="username"
          itemValue="ID"
          :selectedOptions="filtersModel.cbrUser"
          @change="changeFilterMultiple('cbrUser', $event)"
        )
    portal(to="candidates-status")
      div
        select-menu(
          :options="cbrStatusOptions"
          :selectedOptions="filtersModel.cbrStatus"
          @change="changeFilterMultiple('cbrStatus', $event)"
        )
    portal(to="candidates-license-type")
      div
        select-menu(
          :options="licenseTypesOptions"
          :selectedOptions="filtersModel.licenseType"
          @change="changeFilterMultiple('licenseType', $event)"
        )
    portal(to="candidates-product")
      div
        select-menu(
          :getOptions="getProducts"
          itemText="name"
          itemValue="name"
          :selectedOptions="filtersModel.examProduct"
          @change="changeFilterMultiple('examProduct', $event)"
        )
    portal(to="candidates-language")
      div
        select-menu(
          :options="languagesOptions"
          :selectedOptions="filtersModel.language"
          @change="changeFilterMultiple('language', $event)"
        )
    portal(to="candidates-lastError")
      text-menu(
        label="Last error"
        :value="filtersModel.lastError"
        @change="changeFilter('lastError', $event)"
      )

    portal(to="candidates-requests")
      queueDateFiltersDialog(:filters="queueDateFiltersDialogData" @change="changeDialogFilter")
    portal(to="candidates-reserved")
      examDateFiltersDialog(:filters="examDateFiltersDialogData" @change="changeDialogFilter")
</template>

<script>
import {mapActions} from "vuex"
import {CBR_STATUS_OPTIONS, LANGUAGE_OPTIONS, LICENSE_TYPE_OPTIONS} from '../../core/candidates-const'

export default {
  props: {
    filtersModel: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    cbrStatusOptions: CBR_STATUS_OPTIONS,
    licenseTypesOptions: LICENSE_TYPE_OPTIONS,
    languagesOptions: LANGUAGE_OPTIONS
  }),

  computed: {
    queueDateFiltersDialogData() {
      return {
        queueExamDateAfter: this.filtersModel.queueExamDateAfter,
        queueExamDateBefore: this.filtersModel.queueExamDateBefore,
        queueExamDateStrict: this.filtersModel.queueExamDateStrict
      }
    },

    examDateFiltersDialogData() {
      return {
        city: this.filtersModel.city,
        examDateAfter: this.filtersModel.examDateAfter,
        examDateBefore: this.filtersModel.examDateBefore
      }
    }
  },

  methods: {
    ...mapActions({
      _getProducts: 'crmProductNames/list',
    }),

    getProducts() {
      return this._getProducts({cache: true})
    },

    changeFilterMultiple(field, value) {
      this.$emit('changeMultiple', {field: field, value: value})
    },

    changeFilter(field, value) {
      this.$emit('change', {field: field, value: value})
    },

    changeDialogFilter(payload) {
      this.$emit('changeDialogFilters', payload)
    }
  },

  components: {
    selectMenu: () => import('./CandidatesSelectMenu'),
    textMenu: () => import('./CandidateTextMenu.vue'),
    datepicker: () => import('../filters/CandidateDatepickerFilters.vue'),
    filterAutocomplete: () => import('@/components/global/FilterAutocomplete.vue'),
    queueDateFiltersDialog: () => import('./dialogs/queueDateFiltersDialog'),
    examDateFiltersDialog: () => import('./dialogs/examDateFiltersDialog'),
  }
}
</script>
